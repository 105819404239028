import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import MobileSliderWithInfoPanel from "../components/ImgGallery/ImgSliderWithInfoPanel/MobileSliderWithInfoPanel";
import DesktopSliderWithInfoPanel from "../components/ImgGallery/ImgSliderWithInfoPanel/DesktopSliderWithInfoPanel";
import reminderImg from "../assets/img/istockphoto-1238687200-612x612_1.webp";
import reminderImgDesktop from "../assets/img/istockphoto-1238687200-612x612_desktop_1.webp";
import reminderImg2 from "../assets/img/istockphoto-1308717786-612x612.webp";
import reminder2ImgDesktop from "../assets/img/istockphoto-1308717786-612x612_desktop.webp";

function Main() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  let mobileImgList = ["mobile_1.webp", "mobile_2.webp"];

  let desktopImgList = ["desktop_1.webp", "desktop_2.webp"];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className={isMobile ? "" : "padding-inline"}>
      <section id="home">
        {isMobile ? (
          <MobileSliderWithInfoPanel
            Items={mobileImgList}
            maxItems={mobileImgList.length}
            infoTitle={"IHRE FACHÄRZTE"}
            infoHeader={"In Südbrandenburg für Sie da"}
            infoText={
              "Bei uns finden Sie medizinische Vorsorgung in Wohnortnähe.."
            }
            showBtn={true}></MobileSliderWithInfoPanel>
        ) : (
          <DesktopSliderWithInfoPanel
            Items={desktopImgList}
            maxItems={desktopImgList.length}
            infoTitle={"IHRE FACHÄRZTE"}
            infoHeader={"In Südbrandenburg für Sie da"}
            infoText={
              "Bei uns finden Sie medizinische Vorsorgung in Wohnortnähe.."
            }
            showBtn={true}></DesktopSliderWithInfoPanel>
        )}
      </section>
      <section id="welcome-text" className="br-full-rounded">
        <div className="padding-block-700 padding-inline">
          <div className="welcome-text-wrapper">
            <div className="welcome-text-heading">
              <div className="welcome-text-header">
                Medizinisches Versorgungszentrum
              </div>
              <div className="welcome-text-sub-header">Epikur</div>
            </div>
            <p className="welcome-text-paragraph">
              Seit seiner Gründung am 01.01.2007 ist das Medizinische
              Versorgungszentrum Epikur ein fester Bestandteil der
              Gesundheitsversorgung in Bad Liebenwerda und darüber hinaus. Das
              MVZ bietet in 10 Facharztpraxen an 6 Standorten in Südbrandenburg
              eine breite ambulante Versorgung auf hohem und aktuellem
              medizinischen Niveau. Dabei stehen wir Ihnen in verschiedenen
              medizinischen Fachbereichen von Rheumatologie, Urologie, Innere
              Medizin SP Diabetologie und Hals-Nasen-Ohrenheilkunde, bis hin zur
              Kinder- und Jugendmedizin und vielen weiteren, engagiert zur
              Seite.
            </p>
            <br />
            <p className="welcome-text-paragraph">
              Mit Stand vom 01.01.2024 sind wir stolz darauf, ein Team von
              insgesamt 43 engagierten Mitarbeitern zu beschäftigen. Unsere
              Mitarbeiter sind hochqualifizierte Fachleute, die sich mit
              Leidenschaft für die Gesundheit und das Wohlbefinden unserer
              Patienten einsetzen.
            </p>
          </div>
        </div>
      </section>
      <section id="home-info-group">
        <div className="home-info-group-wrapper">
          <div className="padding-block-500 padding-inline">
            <div className="home-info-wrapper">
              <div className="home-info-header">
                Facharztpraxen in Südbrandenburg: Das MVZ Epikur ist für Sie da
              </div>
              <p className="home-info-paragraph">
                Im Medizinischen Versorgungszentrum Epikur steht der Patient im
                Mittelpunkt. Wir legen Wert auf eine ganzheitliche und
                individuelle Betreuung, um die bestmöglichen Ergebnisse für die
                Gesundheit unserer Patienten zu erzielen. Unsere modernen
                Einrichtungen und fortschrittlichen Technologien ermöglichen es
                uns, medizinische Dienstleistungen auf höchstem Niveau
                anzubieten.
              </p>
              <HashLink
                to={"/praxis-finder"}
                className="btn"
                data-type="green-border">
                Arztpraxis in Südbrandenburg finden
              </HashLink>
            </div>
          </div>
        </div>
      </section>
      <section id="home-info-reminder">
        <div className="padding-block-500 padding-inline">
          <div className="home-info-reminder">
            <div className="home-info-reminder-header">
              Mehr über das MVZ Epikur in Südbrandenburg
            </div>
            <div className="home-info-reminder-group">
              <div
                className="home-info-reminder-group-info-item"
                data-type="left">
                <div className="home-info-reminder-group-info-item-header">
                  Fachpraxen in Südbrandenburg
                </div>
                <p className="home-info-reminder-group-info-item-text">
                  Wohnortnahe und ambulante Gesundheitsversorgung zeichnet unser
                  MVZ aus
                </p>
                <HashLink
                  to={"/praxis-finder"}
                  className="btn"
                  data-type="green-border">
                  MVZ Epikur
                </HashLink>
              </div>
              <div
                className="home-info-reminder-group-info-item"
                data-type="right">
                <div className="home-info-reminder-group-info-item-header">
                  Wir sind für Sie da
                </div>
                <p className="home-info-reminder-group-info-item-text">
                  Ihre Meinung und Ihre Wünsche sind uns wichtig. Kontaktieren
                  Sie uns über das Kontaktformular
                </p>
                <HashLink
                  to={"/kontakt"}
                  className="btn"
                  data-type="green-border">
                  Kontaktformular
                </HashLink>
              </div>
              <div className="home-info-reminder-group-info-img-item">
                <img
                  src={isMobile ? reminderImg : reminderImgDesktop}
                  alt="home-info-reminder-group-info-img"></img>
                <div className="home-info-reminder-group-info-img-item-navigation">
                  <div className="btn" data-state="disabled">
                    Werden Sie Teil unseres Teams!
                  </div>
                  <HashLink to={"/jobs"} className="btn" data-type="inverted">
                    Zum Bewerbungsportal
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="home-praxis-search">
        <div className="home-office-search-wrapper br-full-rounded">
          <img
            className="home-office-search-bg-img"
            src={isMobile ? reminderImg2 : reminder2ImgDesktop}
            alt="home-office-search-background-img"></img>
          <div className="home-office-search-control">
            <div className="btn" data-state="disabled" data-color="green">
              Ihr Facharzt in Wohnortnähe
            </div>
            <HashLink
              to={"/praxis-finder"}
              className="btn"
              data-type="inverted-green">
              Arztpraxis finden
            </HashLink>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Main;
