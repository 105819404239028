import React from "react";
import { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import ImageGallery from "../ImageGallery";
import ExtendedServiceCard from "../template/ExtendedServiceCard";
import clockIcon from "../../assets/img/Clock_Icon.webp";
import alertIcon from "../../assets/img/2579410-alert-icon.webp";
import pinIcon from "../../assets/img/location-pin-svgrepo-com 3.webp";
import telIcon from "../../assets/img/contact_phone_icon.webp";
import faxIcon from "../../assets/img/684832 1.webp";

function PraxisTemplateDetailed({ praxisInfos }) {
  const [currentOfficeTime, setCurrentOfficeTime] = useState("");
  var dayIndexArray = { Mo: 0, Di: 1, Mi: 2, Do: 3, Fr: 4, Sa: 5, So: 5 };
  var _dayIndexArray = {
    0: "Mo",
    1: "Di",
    2: "Mi",
    3: "Do",
    4: "Fr",
  };
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  useEffect(() => {
    var options = {
      weekday: "short",
      timeZone: "Europe/Berlin",
    };
    var currentWeekDay = new Date()
      .toLocaleDateString("de", options)
      .replace(".", "");

    var dayIndex = dayIndexArray[currentWeekDay];

    var currentDateString = new Date().toLocaleDateString("de", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    var _currentDate = new Date();

    var isCurrentDayHoldiday = false;

    if (praxisInfos["holidayTimes"]) {
      praxisInfos["holidayTimes"].forEach((element) => {
        if (element.length === 5) {
          var _check1 = currentDateString.substring(0, 5);
          if (element === _check1) isCurrentDayHoldiday = true;
        } else if (element.length === 13) {
          var _check1 = element.substring(0, 5);
          var _check2 = element.substring(8, 13);
          var checkRange = [_check1, _check2];
          var dateRange = [];
          checkRange.forEach((element) => {
            var date = new Date();
            var _day = element.substring(0, 2);
            date.setDate(_day);
            var _month = element.substring(3, 5);
            date.setMonth(_month - 1);
            date.setFullYear("2024");
            var _year = "2024";
            dateRange.push(date);
          });

          if (_currentDate >= dateRange[0] && _currentDate <= dateRange[1]) {
            isCurrentDayHoldiday = true;
          }
        } else {
          var _check1 = element.substring(0, 8);
          var _check2 = element.substring(11, 19);
          var checkRange = [_check1, _check2];
          var dateRange = [];
          checkRange.forEach((element) => {
            var date = new Date();
            console.log(element);
            var _day = element.substring(0, 2);
            date.setDate(_day);
            var _month = element.substring(3, 5);
            date.setMonth(_month - 1);
            var _year = element.substring(6, 8);
            console.log(_year);
            date.setFullYear("20" + _year);
            dateRange.push(date);
          });
          if (_currentDate >= dateRange[0] && _currentDate <= dateRange[1]) {
            isCurrentDayHoldiday = true;
          }
        }
      });
    }

    if (isCurrentDayHoldiday) {
      setCurrentOfficeTime(
        "Es werden heute leider keine Sprechzeiten aufgrund von Urlaub angeboten."
      );
    } else if (currentWeekDay !== "Sa" && currentWeekDay !== "So") {
      var _currentOfficeTime =
        praxisInfos["officeTimes"][dayIndex][currentWeekDay];
      setCurrentOfficeTime(_currentOfficeTime);
    } else {
      setCurrentOfficeTime(
        "Es werden heute leider keine Sprechzeiten angeboten."
      );
    }
  }, [praxisInfos]);

  const getDownloadFile = (file) => {
    /*const _file = require("../../files/" + file["file"]);
    return (
      <div
        className="btn"
        data-type="inverted"
        onClick={() => {
          window.open(_file);
        }}
      >
        {"Download " + file["title"]}
      </div>
    );*/
    return <></>;
  };

  return (
    <section id="praxis-element">
      <div className={isMobile ? "" : "padding-inline"}>
        <div className="praxis-element-heading text-center">
          <div className="current-officeTime-header">
            <img src={clockIcon} alt="uhrIcon"></img>
            <span>{"Heutige Sprechzeiten:"}</span>
          </div>
          <div className="current-officeTime-text">{currentOfficeTime}</div>
          <div className="praxis-element-header">{praxisInfos["fakultat"]}</div>
          <div className="praxis-element-underline"></div>
          <div className="praxis-element-doctor">{praxisInfos["doctor"]}</div>
          <div className="praxis-element-title">{praxisInfos["title"]}</div>
          {praxisInfos["downloads"] ? (
            getDownloadFile(praxisInfos["downloads"])
          ) : (
            <></>
          )}

          <HashLink
            to="#kontakt-sprechzeiten"
            className="btn"
            data-type="inverted"
          >
            Kontakt & Sprechzeiten
          </HashLink>
        </div>
        <div className="container-gray">
          <div className="padding-block-700 padding-inline">
            <p className="praxis-element-welcome-text">
              <span className="fw-bold">Willkommen in unserer Praxis!</span>
              <br />
              Ihre Gesundheit ist unsere Priorität. Unser engagiertes Team steht
              bereit, Sie bestmöglich zu betreuen. Erfahren Sie mehr über unsere
              umfassenden Leistungen, die auf Ihre individuellen Bedürfnisse
              zugeschnitten sind.
            </p>
            <div className="praxis-element-service-wrapper">
              {praxisInfos["services"] ? (
                <>
                  <div className="praxis-element-service-header">
                    Leistungen
                  </div>
                  <div className="praxis-element-extended-service-list">
                    {praxisInfos["services"].map((service, i) => {
                      return (
                        <ExtendedServiceCard
                          serviceTitle={service["title"]}
                          serviceDescription={service["description"]}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}

              {praxisInfos["notes"] ? (
                <div className="praxis-element-service-note">
                  <img src={alertIcon} alt="noteIcon"></img>
                  <span>{praxisInfos["notes"]}</span>
                </div>
              ) : (
                <></>
              )}
              {praxisInfos["extendedNotes"] ? (
                <div className="praxis-element-service-extended-note">
                  <img src={alertIcon} alt="noteIcon"></img>
                  {praxisInfos["extendedNotes"].map((note, i) => {
                    return <p key={i}>{note}</p>;
                  })}
                  <span></span>
                </div>
              ) : (
                <></>
              )}
            </div>
            {praxisInfos["imgList"].length > 0 ? (
              <div className="praxis-element-praxis-gallery">
                <div className="praxis-element-praxis-gallery-header">
                  Unsere Praxis
                </div>
                <div className="praxis-element-praxis-gallery-wrapper">
                  <ImageGallery
                    imgList={praxisInfos["imgList"]}
                    mobile={isMobile}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="contact-control-panel">
          <div className="praxis-element-contact-info  br-full-rounded">
            <div className="praxis-element-contact-info-header">
              Kontaktieren Sie Uns
            </div>
            <div className="praxis-element-contact-info-list">
              <div className="praxis-element-contact-info-item">
                <div className="praxis-element-contact-info-card">
                  <img src={pinIcon} alt="pinIcon"></img>
                </div>
                <div className="praxis-element-contact-info-item-text">
                  {praxisInfos["street"]}
                  <br />
                  {praxisInfos["postalCode"] + " " + praxisInfos["city"]}
                </div>
                <div className="btn" data-type="orange-border">
                  Route berechnen
                </div>
              </div>
              <div className="praxis-element-contact-info-item">
                {isMobile ? (
                  <a href={"tel:+" + praxisInfos["phone"]}>
                    <div className="praxis-element-contact-info-card">
                      <img src={telIcon} alt="telIcon"></img>
                    </div>
                  </a>
                ) : (
                  <div className="praxis-element-contact-info-card">
                    <img src={telIcon} alt="telIcon"></img>
                  </div>
                )}
                <div className="praxis-element-contact-info-item-text">
                  {praxisInfos["phone"]}
                </div>
                <a
                  className="btn"
                  data-type="orange-border"
                  href={"tel:+" + praxisInfos["phone"]}
                >
                  Anrufen
                </a>
              </div>
              {praxisInfos["fax"] ? (
                <div className="praxis-element-contact-info-item">
                  <div className="praxis-element-contact-info-card">
                    <img src={faxIcon} alt="faxIcon"></img>
                  </div>
                  <div className="praxis-element-contact-info-item-text">
                    {praxisInfos["fax"]}
                  </div>
                  <div className="btn" data-type="orange-border">
                    Faxnummer kopieren
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div id="kontakt-sprechzeiten">
          <div className="praxis-element-contact-wrapper padding-block-500 padding-inline">
            <div className="praxis-element-contact-ot">
              <div className="praxis-element-contact-ot-header">
                <img src={clockIcon} alt="uhr"></img>
                <span>Sprechzeiten</span>
              </div>
              <div className="praxis-element-contact-ot-times">
                {praxisInfos["officeTimes"].map((ot, i) => {
                  if (ot[_dayIndexArray[i]]) {
                    return (
                      <div
                        className="praxis-element-contact-ot-time-element"
                        key={i}
                      >
                        {ot[_dayIndexArray[i]]}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div className="praxis-element-contact-ht">
              <div className="praxis-element-contact-ht-header">
                <img src={clockIcon} alt="uhr"></img>
                <span>Urlaubszeiten 2024</span>
              </div>
              <div className="praxis-element-contact-ht-dates">
                {praxisInfos["holidayTimes"].map((ht, i) => {
                  return (
                    <div
                      className="praxis-element-contact-ht-dates-element"
                      key={i}
                    >
                      {ht}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PraxisTemplateDetailed;
