import React, { useState, useRef, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import burgerMenu from "../assets/img/burgerMenu.webp";
import logo from "../assets/img/logo2.webp";
import MobileDropDown from "./Navbar/MobileDropDown";
import chevronUp from "../assets/img/chevron_icon_up_orange.webp";
import chevronDown from "../assets/img/chevron_icon_down_orange.webp";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showServicePopUp, setShowServicePopUp] = useState(false);
  const refServicePopUp = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    if (refServicePopUp.current.contains(e.target)) {
      onServiceBtnClicked();
    } else {
      setShowServicePopUp(false);
    }
  };

  const openMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const onNavLinkClicked = () => {
    setShowMobileMenu(false);
  };

  const onServiceBtnClicked = () => {
    setShowServicePopUp(!showServicePopUp);
  };

  const MobileMenu = () => {
    const ServiceHeader = {
      title: "Praxis finden",
    };

    const ServiceLinks = [
      {
        title: "Praxissuche",
        link: "/praxis-finder",
      },
      {
        title: "Rheumatologie",
        link: "/rheumatologie",
      },
      {
        title: "Kinder- und Jugendmedizin",
        link: "/kinder-und-jugendmedizin",
      },
      {
        title: "Urologie",
        link: "/urologie",
      },
      {
        title: "Frauenheilkunde und Geburtshilfe",
        link: "/frauenheilkunde-geburtshilfe",
      },
      {
        title: "Innere Medizin SP Diabetologie",
        link: "/innere-medizin-diabetologie",
      },
      {
        title: "Hals-Nasen-Ohrenheilkunde",
        link: "/hals-nasen-ohrenheilkunde",
      },
    ];

    {
      /* {
        title: "Orthopädie und Unfallchirurgie",
        link: "/orthopaedie-unfallchirurgie",
      }, */
    }

    if (showMobileMenu) {
      return (
        <div className="nav-mobile-links">
          <HashLink className="nav-link" to="/#top" onClick={onNavLinkClicked}>
            Start
          </HashLink>

          <MobileDropDown
            Header={ServiceHeader}
            Content={ServiceLinks}
            MobileMenuVisible={showMobileMenu}
            LinkClickFunction={onNavLinkClicked}
          />

          <HashLink
            className="nav-link"
            to="/aktuelles"
            onClick={onNavLinkClicked}>
            <span>Aktuelles</span>
          </HashLink>

          <HashLink className="nav-link" to="/jobs" onClick={onNavLinkClicked}>
            <span>Arbeiten Sie bei Uns</span>
          </HashLink>

          <HashLink
            className="nav-link"
            to="/kontakt"
            onClick={onNavLinkClicked}>
            <span>Kontakt</span>
          </HashLink>
        </div>
      );
    }
    return;
  };

  return (
    <header className="primary-header">
      <div className="nav">
        <HashLink to="/#top">
          <img className="nav-logo" src={logo} alt="logo" />
        </HashLink>
        <div className="nav-links">
          <div className="nav-extended-links">
            <HashLink className="nav-link" to="/jobs">
              <span>Karriere</span>
            </HashLink>

            <HashLink className="btn" to="/kontakt">
              <span>Kontakt</span>
            </HashLink>
          </div>
          <div className="nav-mobile">
            <img src={burgerMenu} alt="navMenu" onClick={openMobileMenu} />
          </div>
        </div>
      </div>
      <div className="nav-lower-links-container">
        <div className="nav-lower-links">
          <HashLink className="nav-link" to="/#top">
            <span>WILLKOMMEN</span>
          </HashLink>
          <div
            className="nav-link-dropdown nav-link"
            onClick={onServiceBtnClicked}>
            <div className="nav-link-dropdown-btn">
              <div
                className="nav-link-dropdown-btn-text"
                data-type={showServicePopUp ? "active" : ""}>
                PRAXIS FINDEN
              </div>
              <img
                src={showServicePopUp ? chevronUp : chevronDown}
                alt="chevron-icon"></img>
            </div>

            <div
              className="nav-modal"
              data-type={showServicePopUp ? "" : "inactive"}
              ref={refServicePopUp}>
              <HashLink
                className="nav-modal-link"
                to="/praxis-finder"
                onClick={onNavLinkClicked}>
                Praxissuche
              </HashLink>
              <HashLink
                className="nav-modal-link"
                to="/rheumatologie"
                onClick={onNavLinkClicked}>
                Rheumatologie
              </HashLink>
              <HashLink
                className="nav-modal-link"
                to="/kinder-und-jugendmedizin"
                onClick={onNavLinkClicked}>
                Kinder- und Jugendmedizin
              </HashLink>
              <HashLink
                className="nav-modal-link"
                to="/urologie"
                onClick={onNavLinkClicked}>
                Urologie
              </HashLink>
              <HashLink
                className="nav-modal-link"
                to="/frauenheilkunde-geburtshilfe"
                onClick={onNavLinkClicked}>
                Frauenheilkunde und Geburtshilfe
              </HashLink>
              <HashLink
                className="nav-modal-link"
                to="/innere-medizin-diabetologie"
                onClick={onNavLinkClicked}>
                Innere Medizin SP Diabetologie
              </HashLink>
              <HashLink
                className="nav-modal-link"
                to="/hals-nasen-ohrenheilkunde"
                onClick={onNavLinkClicked}>
                Hals-Nasen-Ohrenheilkunde
              </HashLink>
              {/*  <HashLink
                className="nav-modal-link"
                to="/orthopaedie-unfallchirurgie"
                onClick={onNavLinkClicked}>
                Orthopädie und Unfallchirurgie
              </HashLink> */}
            </div>
          </div>

          <HashLink className="nav-link" to="/jobs">
            <span>ARBEITEN SIE MIT UNS</span>
          </HashLink>
          <HashLink className="nav-link" to="/aktuelles">
            <span>AKTUELLES</span>
          </HashLink>
          <div className="nav-placeholder"></div>
          <div className="nav-placeholder"></div>
          <div className="nav-placeholder"></div>
        </div>
      </div>
      <MobileMenu />
    </header>
  );
}

export default Navbar;
